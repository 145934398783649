const navbarAll = "#ffffff"
const darkAll = "#000000"
const secondColorAll = "#27b88b"


export const header = "#012a45";
// export const navbar = "#000000";
// export const lightText = "#4f4c4c";
export const darkText = "#000000";
export const lightButton = "#eeecff";
export const darkButton = "#cf2727";
export const dimBackground = "#e6e3e3";
export const lightBackground = darkAll;
export const darkBackground = "#303030";
export const white = "#ffffff";
export const black = "#1a2131";

//theme color for Brand Invoice

export const brandName = "Tricentric";
export const brandUrl = "tricentric.co";

export const navbar = "#FD0003";
export const navbarText = darkAll;
export const lightText = "#ACACAC";
export const mainBackgound = darkAll;
export const invoiceCard = "#F4F4F4";
export const button = "#FD0003";
export const cardBackground = "#fffff";
export const lightBorder = "#E2E2E2";


// 002e47

